<template>
  <section id="FordPlazaFieldView">
    <!-- <EventSpacesCarousel :carouselImages="carouselImages" /> -->
  </section>
</template>

<script>
  // import EventSpacesCarousel from '@/components/global/EventSpacesCarousel'
  // import { getFieldViewImages } from '@/data/sectionData/eventSpaces/FordPlaza'

  export default {
    // name: "FordPlazaFieldView",
    // components: {
    //   EventSpacesCarousel
    // },
    // data() {
    //   return {
    //     carouselImages: getFieldViewImages()
    //   }
    // }
};
</script>

<style lang="scss" scoped>

</style>
